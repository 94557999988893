import { Component, Input, Output, EventEmitter, OnInit } from "@angular/core";

@Component({
  selector: "confect-feature-banner",
  templateUrl: "./feature-banner.component.html",
})
export class CFeatureBannerComponent implements OnInit {
  @Input() title = "";
  @Input() text = "";
  @Input() buttonText = "";

  @Output() buttonClick = new EventEmitter();

  ngOnInit(): void {}
}
