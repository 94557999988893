<div
  class="grid py-4 px-4 gap-x-4"
  [ngClass]="{ 'grid-cols-2 max-w-3xl': !slim, 'grid-cols-1 max-w-sm': slim }"
>
  <div class="flex flex-col justify-center py-8 gap-y-8">
    <h2 class="text-3xl font-bold tracking-tight text-black 2xl:text-4xl">
      Confect for Chrome
    </h2>
    @if (slim) {
      <div class="flex items-center justify-center">
        <div class="w-full h-full">
          <img
            class="object-contain"
            src="/assets/images/empty-states/automation-variants.png"
          />
        </div>
      </div>
    }
    <p class="2xl:text-lg leading-6 text-gray-700">
      To use multiple designs in the same catalog, you need to install the
      Confect Chrome Plugin. It allows you to change design variants directly in
      the Ads Manager.
    </p>
    <confect-button size="xlarge" [type]="buttonType" (click)="openWebStore()"
      ><confect-icon
        icon="download_to"
        iconClass="text-xl 2xl:text-2xl mr-1 mt-1 xl:mt-0 opacity-70 flex items-center"
        class="2xl:text-lg font-semibold"
        >Add to Chrome - It’s free</confect-icon
      ></confect-button
    >
  </div>
  @if (!slim) {
    <div class="flex items-center justify-center">
      <div class="w-full h-full flex items-center justify-center">
        <img
          class="object-contain"
          src="/assets/images/empty-states/automation-variants.png"
        />
      </div>
    </div>
  }
</div>
