import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatMomentDateModule } from "@angular/material-moment-adapter";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatMenuModule } from "@angular/material/menu";
import { MatTooltipModule } from "@angular/material/tooltip";
import { RouterModule } from "@angular/router";

import { DragModule } from "@components/drag/drag.module";

import { SafePipe } from "@theme/pipes";

import {
  NgxMatDatetimePickerModule,
  NgxMatNativeDateModule,
} from "@angular-material-components/datetime-picker";
import { NgSelectModule } from "@ng-select/ng-select";

import { BalloonMenuComponent } from "../../@theme/@confect/components/balloon-menu/balloon-menu.component";
import { CAccordionItemComponent } from "./components/accordion/accordion-item.component";
import { CAccordionComponent } from "./components/accordion/accordion.component";
import { CActionButtonComponent } from "./components/action-button/action-button.component";
import { CAlignmentComponent } from "./components/alignment/alignment.component";
import { CButtonComponent } from "./components/button/button.component";
import { CCalendarComponent } from "./components/calendar/calendar.component";
import { CCategoryMenuComponent } from "./components/category-menu/category-menu.component";
import { CSettingsBoxComponent } from "./components/confect-setting-box/confect-settings-box.component";
import { ConfectSpinnerComponent } from "./components/confect-spinner/confect-spinner.component";
import { ContextMenuOutletComponent } from "./components/context-menu/context-menu-outlet/context-menu-outlet.component";
import { CContextMenuComponent } from "./components/context-menu/context-menu.component";
import { DealOwnerComponent } from "./components/deal-owner/deal-owner.component";
import { DialogBoxOutletComponent } from "./components/dialog-box/dialog-box-outlet/dialog-box-outlet.component";
import { DialogBoxTargetDirective } from "./components/dialog-box/dialog-box-target/dialog-box-target.directive";
import { DialogBoxToggleDirective } from "./components/dialog-box/dialog-box-toggle/dialog-box-toggle.directive";
import { DialogBoxComponent } from "./components/dialog-box/dialog-box.component";
import { DialogBoxDirective } from "./components/dialog-box/dialog-box.directive";
import { CDropdownComponent } from "./components/dropdown/dropdown.component";
import { CExtensionInstallPromptComponent } from "./components/extension-install-prompt/extension-install-prompt.component";
import { CFeatureBannerComponent } from "./components/feature-banner/feature-banner.component";
import { CCheckboxComponent } from "./components/form/elements/checkbox/checkbox.component";
import { CInputComponent } from "./components/form/elements/input/input.component";
import { CSelectComponent } from "./components/form/elements/select/select.component";
import { CToggleComponent } from "./components/form/elements/toggle/toggle.component";
import { CUploadComponent } from "./components/form/elements/upload/upload.component";
import { CFormInlineGroupComponent } from "./components/form/layouts/form-inline-group/form-inline-group.component";
import { CIconButtonComponent } from "./components/icon-button/icon-button.component";
import { CIconComponent } from "./components/icon/icon.component";
import { CLoadingComponent } from "./components/loading/loading.component";
import { CModalInputComponent } from "./components/modal-input/modal-input.component";
import { CModalOutletComponent } from "./components/modal-outlet/modal-outlet.component";
import { CModalSuccessComponent } from "./components/modal-success/modal-success.component";
import { CModalTemplateComponent } from "./components/modal-template/modal-template.component";
import { CModalUploadComponent } from "./components/modal-upload/modal-upload.component";
import { CModalWarningComponent } from "./components/modal-warning/modal-warning.component";
import { CModalComponent } from "./components/modal/modal.component";
import { CPagerComponent } from "./components/pager/pager.component";
import { PluginNoticeComponent } from "./components/plugin-notice/plugin-notice.component";
import {
  CRadioSelectCardComponent,
  CRadioSelectCardsComponent,
} from "./components/radio-select-cards/radio-select-cards.component";
import { CRotationComponent } from "./components/rotation/rotation.component";
import { CSlideOverComponent } from "./components/slide-over/slide-over.component";
import { CSliderComponent } from "./components/slider/slider.component";
import { CStackedListComponent } from "./components/stacked-list/stacked-list.component";
import { CTableCellComponent } from "./components/table/table-cell/table-cell.component";
import { CTableRowComponent } from "./components/table/table-row/table-row.component";
import { CTableComponent } from "./components/table/table.component";
import { TooltipOutletComponent } from "./components/tooltip/tooltip-outlet/tooltip-outlet.component";
import { CTooltipComponent } from "./components/tooltip/tooltip.component";
import { CTooltipDirective } from "./components/tooltip/tooltip.directive";
import { UploadImageDirective } from "./directives/upload-image/upload-image.directive";
import { CSideMenuComponent } from "./navigation/side-menu/side-menu.component";
import {
  CCardAccComponent,
  CCardBodyComponent,
  CCardComponent,
  CCardFooterComponent,
  CCardHeaderComponent,
  CCardAccSearchComponent,
} from "./wrappers/card";
import { CMainWrapperComponent } from "./wrappers/confect-main-wrapper/confect-main-wrapper.component";
import { CPageWrapperComponent } from "./wrappers/confect-page-wrapper/confect-page-wrapper.component";
import { SideScrollComponent } from "./wrappers/side-scroll/side-scroll.component";
import { SplitSubViewComponent } from "./wrappers/split-view/split-sub-view/split-sub-view.component";
import { SplitViewContentComponent } from "./wrappers/split-view/split-view-content/split-view-content.component";
import { SplitViewComponent } from "./wrappers/split-view/split-view.component";
import { SplitContentDirective } from "./wrappers/split-view/split-view.directive";
import { CStepComponent } from "./wrappers/stepper/step/step.component";
import { CStepperComponent } from "./wrappers/stepper/stepper.component";
import { CTabContentComponent } from "./wrappers/tab-menu/tab-content/tab-content.component";
import { CTabMenuComponent } from "./wrappers/tab-menu/tab-menu.component";
import {
  TabDesignDirective,
  TabContentDirective,
} from "./wrappers/tab-menu/tab-menu.directive";

const COMPONENTS = [
  CAccordionComponent,
  CAccordionItemComponent,
  CActionButtonComponent,

  CCardAccComponent,
  CCardBodyComponent,
  CCardComponent,
  CCardFooterComponent,
  CCardHeaderComponent,
  CCardAccSearchComponent,
  CCheckboxComponent,
  CDropdownComponent,
  CButtonComponent,
  CFormInlineGroupComponent,
  CIconComponent,
  CInputComponent,
  CMainWrapperComponent,
  CModalComponent,
  CPageWrapperComponent,
  CRadioSelectCardComponent,
  CRadioSelectCardsComponent,
  CSelectComponent,
  CSideMenuComponent,
  CSlideOverComponent,
  CStackedListComponent,
  CModalSuccessComponent,
  CModalOutletComponent,

  CTableComponent,
  CTableRowComponent,
  CTableCellComponent,
  CLoadingComponent,
  CModalWarningComponent,
  CModalTemplateComponent,
  CCalendarComponent,
  CModalInputComponent,
  CUploadComponent,
  CModalUploadComponent,
  CContextMenuComponent,
  CCategoryMenuComponent,
  CPagerComponent,
  CIconButtonComponent,
  CStepperComponent,
  CStepComponent,
  CSliderComponent,
  CTabMenuComponent,
  CTabContentComponent,
  BalloonMenuComponent,
  CToggleComponent,
  ContextMenuOutletComponent,
  SplitViewComponent,
  SplitSubViewComponent,
  SplitViewContentComponent,
  SideScrollComponent,
  CAlignmentComponent,
  CRotationComponent,
  TooltipOutletComponent,
  CTooltipComponent,
  CSettingsBoxComponent,
  ConfectSpinnerComponent,
  DealOwnerComponent,
  DialogBoxComponent,
  DialogBoxOutletComponent,
  PluginNoticeComponent,
  CFeatureBannerComponent,
  CExtensionInstallPromptComponent,
];

const DIRECTIVES = [
  UploadImageDirective,
  TabDesignDirective,
  TabContentDirective,
  SplitContentDirective,
  CTooltipDirective,
  DialogBoxDirective,
  DialogBoxToggleDirective,
  DialogBoxTargetDirective,
];

const EXT_MODULES = [
  MatFormFieldModule,
  NgxMatDatetimePickerModule,
  MatMomentDateModule,
  NgxMatNativeDateModule,
  MatDatepickerModule,
  MatMenuModule,
  MatTooltipModule,
];

@NgModule({
  declarations: [...COMPONENTS, ...DIRECTIVES, SafePipe],
  imports: [
    CommonModule,
    NgSelectModule,
    FormsModule,
    RouterModule,
    DragModule,
    ...EXT_MODULES,
  ],
  providers: [],
  exports: [...COMPONENTS, ...DIRECTIVES, NgSelectModule, SafePipe],
})
export class ConfectModule {}
