<ng-template #item>
  <ng-select
    #selector
    [(ngModel)]="_value"
    (ngModelChange)="onChange($event)"
    (change)="change.emit($event)"
    (focus)="focused = true"
    (blur)="focused = false"
    appendTo="body"
    [clearable]="clearable"
    [multiple]="multiple"
    [groupBy]="groupBy"
    [searchable]="searchable"
    [dropdownPosition]="dropdownPosition"
    [bindLabel]="bindLabel"
    [bindValue]="bindValue"
    [placeholder]="placeholder"
    [items]="options"
    [ngClass]="{
      focus: focused,
      'confect-select': !focused,
      base: size === 'base',
      xlarge: size === 'xlarge',
      'extra-height': size === 'extra-height',
      standard: styling === 'standard',
      subtle: styling === 'subtle',
    }"
  >
    <ng-template
      ng-option-tmp
      let-item="item"
      let-item$="item$"
      let-index="index"
    >
      <span
        class="block w-full whitespace-pre-wrap {{ size }} text-xs"
        tooltip
        tooltipPosition="right"
        [tooltipDisable]="!item.disabled && item.tooltipDisable"
        [tooltipTemplate]="item.disableTemp"
        [tooltipExtra]="{ plans: item.plans }"
        [title]="item$.label"
        >{{ item$.label }}
      </span>
      @if (bindSubLabel) {
        <span
          class="block max-w-full mr-4 text-xs text-gray-600 overflow-ellipsis"
          >{{ item[bindSubLabel] }}</span
        >
      }
    </ng-template>

    @if (staticLabel || preLabel; as item) {
      <ng-template ng-label-tmp let-item="item">
        <div class="flex">
          <div
            class="flex flex-shrink text-gray-400"
            [ngClass]="{ 'pr-2': inputIcon != null || inputText != null }"
          >
            <confect-icon [icon]="inputIcon">{{ inputText }}</confect-icon>
          </div>
          <div class="{{ size }}">
            {{
              staticLabel
                ? staticLabel
                : preLabel + ": " + (item[bindLabel] ?? item)
            }}
          </div>
        </div>
      </ng-template>
    } @else {
      <ng-template ng-label-tmp let-item="item"
        ><div class="flex w-full">
          <div
            class="flex flex-shrink text-gray-400"
            [ngClass]="{ 'pr-2': inputIcon != null || inputText != null }"
          >
            <confect-icon [icon]="inputIcon">{{ inputText }}</confect-icon>
          </div>
          <div class="w-full">{{ item[bindLabel] ?? item }}</div>
        </div>
      </ng-template>
    }
  </ng-select>
</ng-template>

@if (title !== "" && title != null) {
  <div
    class="grid gap-4 items-start pb-3"
    [ngClass]="{ 'grid-cols-5': compact, 'grid-cols-2': !compact }"
  >
    <div
      class="mt-px col-span-3 flex items-center h-full"
      [ngClass]="{ 'col-span-1': !compact, 'col-span-3': compact }"
    >
      <label [for]="name" class="block">
        <confect-icon
          [icon]="icon"
          iconClass="text-xl font-medium text-gray-700 mr-2"
        >
          <span class="text-sm">{{ title }}</span>
        </confect-icon>
      </label>
    </div>
    <div [ngClass]="{ 'col-span-1': !compact, 'col-span-2': compact }">
      <ng-container *ngTemplateOutlet="item" />
    </div>
  </div>
}

@if (title === "" || title == null) {
  <ng-container [ngTemplateOutlet]="item" />
}
