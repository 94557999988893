<div>
  <div class="flex items-center mb-4">
    <span class="flex-grow text-gray-700 text-lg font-bold">{{
      error?.description
    }}</span>
  </div>

  <div>
    @if (error?.key === "FormatError") {
      <p>
        Confect supports Google Shopping Feeds in either
        <a
          class="font-semibold underline"
          href="https://support.google.com/merchants/answer/160589"
          target="_blank"
          >RSS 2.0</a
        >
        or
        <a
          class="font-semibold underline"
          href="https://support.google.com/merchants/answer/160593"
          target="_blank"
          >Atom 1.0</a
        >
        format.
      </p>
      <p>
        More information about the Google Shopping Format can be found
        <a
          class="font-semibold underline"
          href="https://support.google.com/merchants/answer/7052112?hl=en"
          target="_blank"
          >here</a
        >.
      </p>
    }

    @if (error?.key === "InvalidProductsError") {
      <div class="flex flex-col space-y-2">
        <p>The feed has products with missing required values.</p>
        <p>
          More information about the Google Shopping Format can be found
          <a
            class="font-semibold underline"
            href="https://support.google.com/merchants/answer/7052112?hl=en"
            target="_blank"
            >here</a
          >.
        </p>
        <div>
          <span class="font-bold">Missing fields:</span>
          <ul class="list-disc px-8 py-2">
            @for (field of error.errorData?.data; track field) {
              <li>
                {{ field }}
              </li>
            }
          </ul>
        </div>
      </div>
    }

    @if (error?.key === "DownloadError") {
      <p>
        {{ error?.errorData?.message }}
      </p>
    }

    @if (error?.key === "EmptyError") {
      <p>
        The feed is in a valid format, but contains no products. Check your
        product feed provider for any errors.
      </p>
    }

    @if (error?.key === "OtherError") {
      <div class="p-8">
        <ul class="list-disc">
          @for (err of error.errorData?.data; track err) {
            <li>
              {{ err }}
            </li>
          }
        </ul>
      </div>
    }

    @if (error?.key === "UnknownError") {
      <p>
        The feed does not work for an unknown reason. Please contact support for
        more help.
      </p>
    }

    @if (error?.key === "NoProductsOnSaleWarning") {
      <p>We could not detect any products on sale in this feed.</p>
      <p>
        If this is correct, then there are no issues. Otherwise this is probably
        an issue on your end. Check that both price and sale_price is included
        in the feed.
      </p>
    }
  </div>
</div>
