<div
  class="w-[32rem] bg-white ring-2 ring-inset ring-gray-200 rounded-md flex flex-col"
>
  <div class="flex justify-start gap-8 px-5 py-5">
    <div class="flex w-1/3">
      <img
        [src]="dealOwner.image_url"
        class="w-full h-full object-cover aspect-square rounded-full"
      />
    </div>
    <div class="w-2/3 flex flex-col justify-center gap-4">
      <div class="flex flex-col justify-center">
        <div class="text-lg font-semibold">
          {{ dealOwner.full_name }}
        </div>
        <div class="text-sm text-gray-500">Onboarding Manager</div>
      </div>
      <div
        class="px-2 py-3 text-sm bg-slate-100 ring-2 ring-inset ring-slate-200 rounded-md"
      >
        I am your first point of contact for anything Confect! Feel free to
        reach out.
      </div>
    </div>
  </div>
  <div class="flex border-t-2 divide-x-2 justify-center">
    <div class="px-5 py-3 flex items-center justify-center w-1/2">
      <a
        href="mailto:{{ dealOwner.email }}"
        target="_blank"
        class="w-full flex items-center justify-center"
        ><confect-icon
          icon="email"
          iconClass="text-3xl mr-1 text-gray-300"
          class="text-lg font-semibold"
          >{{ dealOwner.email }}</confect-icon
        ></a
      >
    </div>
    <div class="px-5 py-3 flex items-center justify-center w-1/2">
      <a
        [href]="dealOwner.meeting_link"
        target="_blank"
        class="w-full flex items-center justify-center"
      >
        <confect-icon
          icon="calendar"
          iconClass="text-3xl mr-1 text-gray-300"
          class="text-lg font-semibold"
          >Book a Meeting</confect-icon
        ></a
      >
    </div>
  </div>
</div>
