<div class="py-5">
  <div class="flex justify-between rounded-lg shadow-lg p-5 bg-primary-600">
    <div class="flex justify-between items-center">
      <div class="pr-6">
        <confect-icon icon="error_outlined" iconClass="text-4xl text-white" />
      </div>
      <div>
        <h3 class="text-white">{{ title }}</h3>
        <p class="text-white pt-1">{{ text }}</p>
      </div>
    </div>
    <div class="flex justify-end items-center min-w-56 px-2">
      @if (buttonText !== "") {
        <confect-button type="white" (click)="buttonClick.emit()">
          {{ buttonText }}
        </confect-button>
      }
    </div>
  </div>
</div>
