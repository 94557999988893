import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
} from "@angular/core";

import {
  TooltipRef,
  TooltipService,
} from "@theme/@confect/services/confect-tooltip.service";

import { Subject, takeUntil } from "rxjs";

@Directive({
  selector: "[tooltip]",
})
export class CTooltipDirective implements OnDestroy, OnInit {
  unsubscribe$ = new Subject<any>();

  @Input() tooltipTemplate: TemplateRef<any>;
  @Input() tooltipText: string;
  @Input() tooltipExtra: any;
  @Input({ required: true }) tooltipPosition:
    | "above"
    | "below"
    | "left"
    | "right";
  @Input() tooltipDisable: boolean = false;

  tooltipRef: TooltipRef;
  tooltipHover: boolean = false;
  hover: boolean = false;
  debouncer: NodeJS.Timeout;

  @HostListener("mouseenter", ["$event"]) hoverStart(e: MouseEvent) {
    if (this.debouncer) {
      this.tooltipRef?.switch();
      clearTimeout(this.debouncer);
      delete this.debouncer;
    }
    this.hover = true;
    if (
      this.tooltipDisable ||
      (this.tooltipTemplate == null && this.tooltipText == null)
    ) {
      if (this.tooltipRef != null) {
        this.destroy();
      }
      return;
    }
    if (this.tooltipRef != null) {
      return;
    }
    const boundingRect = this.elementRef.nativeElement.getBoundingClientRect();
    this.tooltipRef = this.tooltipService.open({
      ...(this.tooltipTemplate != null && { template: this.tooltipTemplate }),
      ...(this.tooltipText != null && { tooltipText: this.tooltipText }),
      extra: this.tooltipExtra,
      position: this.tooltipPosition,
      parentPosition: boundingRect,
      event: e,
    });

    this.tooltipRef.afterClose.pipe(takeUntil(this.unsubscribe$)).subscribe({
      next: () => {
        this.tooltipRef = null;
        delete this.debouncer;
      },
    });
    this.tooltipRef.hovered.pipe(takeUntil(this.unsubscribe$)).subscribe({
      next: (res: boolean) => {
        if (this.debouncer && res) {
          this.tooltipRef?.switch();
          clearTimeout(this.debouncer);
          delete this.debouncer;
        }
        this.tooltipHover = res;
        setTimeout(() => {
          if (!res && !this.hover) {
            this.destroy();
          }
        }, 10);
      },
    });
  }

  @HostListener("mouseleave", ["$event"]) hoverStop(e: MouseEvent) {
    setTimeout(() => {
      this.hover = false;

      if (this.tooltipRef == null || this.tooltipHover) {
        return;
      }
      this.destroy();
    }, 10);
  }
  @HostListener("wheel", ["$event"]) scroll(e: MouseEvent) {
    this.destroy();
  }

  constructor(
    private tooltipService: TooltipService,
    private elementRef: ElementRef,
  ) {}

  destroy() {
    this.tooltipRef?.switch();
    this.debouncer = setTimeout(() => {
      this.tooltipRef?.close();
      this.unsubscribe$.next(true);
    }, 300);
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    if (this.tooltipRef != null) {
      this.tooltipRef.close();
    }
    this.unsubscribe$.next(true);
  }
}
