import { AccountSubscriptionInfo } from "../models/pricing.types";
import { TrialInfo } from "../models/pricing.types";
import { BillingState } from "./settings.models";

export interface AccountFeatureMeta {
  name: string;
  key: string;
  icon?: string;
}

export interface CompanySlim {
  id: number;
  active: boolean;
  name: string;
}

export enum ImageSettingsMode {
  REMOVE_BG = "REMOVE_BG",
  MIX = "MIX",
  DO_NOT_REMOVE_BG = "DO_NOT_REMOVE_BG",
}

export interface ImageSettings {
  mode: ImageSettingsMode;
  mix_settings: { exclude: boolean; product_filter: any };
}

export interface Account {
  id: number;
  active: boolean;
  name: string;
  permissions: Permissions;
  trial_end_date: string;
  pricing: AccountSubscriptionInfo;
  stripe_subscription_id?: string;
  image_settings: ImageSettings;
  features: AccountFeature[];
  created_at: Date;
  onboarding_done: boolean;
  discount?: number;
}

export interface CompanySubscriptionInfo {
  trial_end_date: Date | null;
  state: BillingState;
}

export interface CompanyAdminInfo {
  is_paying: boolean;
  subscription_v2: boolean;
  subscription_info: CompanySubscriptionInfo | null;
}

export interface Company {
  id: number;
  name: string;
  active: boolean;
  accounts: Account[];
  permissions: Permissions;
  discount: number;
  agency?: { id: number; name: string };
  created_at: Date;
  deal_owner: { id: number; name: string } | null;

  admin_info: CompanyAdminInfo;
}

export interface CompanyUser {
  id: number;
  name: string;
  email: string;
  active: boolean;
}

export type Permissions = Record<string, boolean>;

export interface AccountFeature extends AccountFeatureMeta {
  trial_end_date: string;
  cancelled_at?: string;
}

// export interface TransparencySettingsFilter {
//   exclude: boolean;
//   filter: any;
// }

// export interface FeedTransparencySettings {
//   // Exclude filters
//   exclude: boolean;
//   filters: TransparencySettingsFilter[];
// }

export interface FeedWidgetItem {
  account_id: number;
  account_name: string;
  company_id: number;
  company_name: string;
  feed_id: number;
  feed_name: string;
}

export interface TrialCompany {
  id: number;
  name: string;
  trial_end_date: string;
  trial_info: TrialInfo;
}

export interface User {
  id: number;
  name: string;
  email: string;
  active: boolean;
}

export interface UserAgency {
  id: number;
  name: string;
}

export interface UserDetailed extends User {
  permissions: Record<string, boolean>;
  companies: CompanySlim[];
  agency?: UserAgency;
}

export interface AdminAgencyUser {
  id: number;
  name: string;
  email: string;
  active: boolean;
}

export interface AdminAgencyCompany {
  id: number;
  name: string;
  active: boolean;
}

export interface AdminAgency {
  id: number;
  name: string;
  active: boolean;
  users?: AdminAgencyUser[];
  companies?: AdminAgencyCompany[];
}
