<button
  (click)="buttonClick($event)"
  [type]="submit ? 'submit' : 'button'"
  class="button"
  [disabled]="disabled"
  [ngClass]="{
    danger: type === 'danger',
    disabled: disabled,
    large: size === 'large',
    medium: size === 'medium',
    primary: type === 'primary',
    secondary: type === 'secondary',
    subtle: type === 'subtle',
    small: size === 'small',
    white: type === 'white',
    xlarge: size === 'xlarge',
    xsmall: size === 'xsmall',
    xxlarge: size === 'xxlarge',
    'button-block': block === true,
    active: active,
  }"
  #anchor
>
  <ng-content />
</button>

@if (showMenu) {
  <confect-dropdown
    #dropdown
    [items]="dropdownItems"
    [anchor]="anchor"
    [listen]="showMenu"
    (dropdownItemSelected)="itemSelected($event)"
  />
}
