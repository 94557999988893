import {
  Component,
  ComponentRef,
  OnDestroy,
  OnInit,
  Type,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";

import { Subject, first, takeUntil } from "rxjs";

import { TooltipService } from "../../../services/confect-tooltip.service";
import { CTooltipComponent } from "../tooltip.component";

@Component({
  selector: "confect-tooltip-outlet",
  templateUrl: "./tooltip-outlet.component.html",
})
export class TooltipOutletComponent implements OnInit, OnDestroy {
  destroy$ = new Subject();

  /** The target point in template where all modal-components are bing inserted. */
  @ViewChild("tooltipTarget", { read: ViewContainerRef, static: true })
  tooltipContainerRef: ViewContainerRef;

  constructor(private tooltipService: TooltipService) {}

  /** Destroys the `content` and the `modal` itself. */
  private static destroyContent(content: ComponentRef<any>): void {
    content.destroy();
  }

  ngOnInit(): void {
    this.handleNewModal();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
  }

  /** Listens to `modalService.modalObservable` and loads/appends the modal-component. */
  private handleNewModal(): void {
    this.tooltipService.tooltipObservable
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (opts) => {
          opts.event.preventDefault();
          const tooltipComponent = this.loadComponent<CTooltipComponent>(
            CTooltipComponent,
            this.tooltipContainerRef,
            {
              template: opts.template,
              extra: opts.extra,
              parentPosition: opts.parentPosition,
              position: opts.position,
              tooltipText: opts.tooltipText,
            },
          );

          opts.tooltipRef.close = () => tooltipComponent.instance.close();
          opts.tooltipRef.switch = () => tooltipComponent.instance.switch();
          opts.tooltipRef.afterClose = tooltipComponent.instance.afterClose;
          opts.tooltipRef.hovered = tooltipComponent.instance.hovered;
          tooltipComponent.instance.afterClose.pipe(first()).subscribe({
            next: () => {
              TooltipOutletComponent.destroyContent(tooltipComponent);
            },
          });
        },
      });
  }
  /** Loads the `component` into `parentContainerRef`. */
  private loadComponent<C>(
    component: Type<any>,
    parentContainerRef: ViewContainerRef = this.tooltipContainerRef,
    inputs = {},
    outputs?,
  ): ComponentRef<C> {
    const componentRef = parentContainerRef.createComponent(component);

    // Pass all @Input bindings
    Object.keys(inputs).forEach((input) => {
      componentRef.setInput(input, inputs[input]);
    });

    // Pass all @Output bindings
    if (outputs) {
      Object.keys(outputs).forEach((output) => {
        outputs[output] = componentRef.instance[output];
      });
    }

    return componentRef as ComponentRef<C>;
  }
}
