import { Component, Input } from "@angular/core";

@Component({
  selector: "confect-extension-install-prompt",
  templateUrl: "./extension-install-prompt.component.html",
})
export class CExtensionInstallPromptComponent {
  @Input() slim: boolean = false;
  @Input() buttonType: "white" | "primary" | "secondary" = "primary";

  openWebStore() {
    window.open(
      "https://chrome.google.com/webstore/detail/confect-analytics/leknliicffobjlhilhmijnbmmjfigjme",
      "_blank",
    );
  }
}
